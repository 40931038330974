<!--
 * @FileDescription:网盟用户端官网
 * @Author: 蔡林泽
 * @Date: 2021/2/12
 * @LastModifiedTime: 2021/2/12
 -->
<template>
  <WebsiteNavigation>
    <template slot="content">
      <div class="main">
        <h1 style="margin:50px 0">Privacy Policy</h1>
        <div>
          <p>
            Thank you for trusting and choosing KakaClo (hereinafter “we” or “us”) services. This Privacy Policy is developed under relevant law and regulations in China. We strongly recommend that you (hereinafter “User”) should carefully read all the clauses of this Policy, especially the ones regarding your critical interest, disclaimers, applicable laws, and dispute resolutions. By using KakaClo services, you agree to comply with the following terms and conditions in this Privacy Policy.
            We respect and protect the privacy rights of all internet users. We will use and disclose your personal information according to this Privacy Policy to provide more accurate and personalized services. Meanwhile, we will handle such information in a meticulous and responsible manner. Unless otherwise specified in the Privacy Policy, we will not disclose such information to the public or any third party without your consent. You agree and acknowledge that we reserve the right to modify this Privacy Policy from time to time at our sole discretion. You accept that this Privacy Policy is an integral part of the KakaClo's User Agreement. You're deemed to comply with this Privacy Policy when you agree to our User Agreement.
            <br><span class="weight">I. Definitions</span><br>
            The terms listed below represent the same meanings and interpretations when they appear on KakaClo's website.
            Account: refers to the account legally registered by KakaClo user through KakaClo.
            KakaClo, KakaClo Company, the company: refer to the single name or collective name of the operator of KakaClo platform, including but not limited to Shenzhen KakaClo Technology Co., Ltd. and other affiliated companies.
            Website: KakaClo.com, as the official website of Shenzhen KakaClo Technology Co., Ltd.
            China: refers to the abbreviation of the People's Republic of China, including Hong Kong, Macao and Taiwan.
            Cookies: Small text files that are placed in your PC, mobile device, or any other device, containing a detailed history of your browsing on the website and other information.
            Device: mobile phone, PC, or other devices accessing KakaClo.com.
            User Information: the information needed to register on KakaClo, browse KakaClo's website or use KakaClo Services, and the data generated during such processes.
            Usage Data: the data automatically collected when browsing the website or generated while using the service
            Service: all kinds of service provided by Shenzhen KakaClo Technology Co., Ltd.
            Service Provider: a third-party company or individual providing services on behalf of KakaClo Company.
            Third Party Service Provider: any natural person, legal person, or unincorporated organization other than KakaClo Company that provides services or software, or sells goods. Partner: Carriers (such as DHL), third-party payment processors (PayPal, Stripe, and Payoneer), Shopify, WooCommerce, Shipstation, Shopmaster, Lazada, Shopee.
            Essential Information: certain information is required for our service. If you choose to not provide it, our service provided to you will be affected.
            Non-essential Information: information you give us. You can choose not to provide such information as our service provided to you will not be affected.

            <br><span class="weight">II. Legal Statement:</span><br>
            You confirm that your personal information provided to KakaClo or collected by KakaClo with your authorization must be authentic, correct, complete, legal and effective, and should be updated in time for any alteration of such information. You acknowledge that KakaClo has the right to verify the authenticity of your personal information via a credit institution or a linked bank account. You should be responsible for relevant liabilities and consequences for providing illegal, inauthentic, incorrect, or incomplete personal information and you agree that KakaClo has the right to stop providing service for you in such cases.
            Unless otherwise specified, all products, technologies, programs, software, and data of KakaClo, relevant domains, symbols, names, and other information involving relevant intellectual rights of KakaClo are the properties of KakaClo and its associates. Unless authorized by KakaClo, anyone has no rights to surveil, copy, broadcast, display, or download such properties via robot or spider program for unauthorized use of his own or any other individual or party.
            You shall be liable for all contents uploaded by yourself, including but not limited to reviews, used items, texts, pictures, videos, etc. Such content is delivered by KakaClo for sharing purposes, which doesn't mean that KakaClo agrees with it or has verified the authenticity of it.

            <br><span class="weight">III. What Information Does KakaClo Collect?</span><br>
            1. Certain information is required for our service. If you choose to accept our service, we should be provided with or authorized to collect 9 types of Essential Information including:
            <br>(1) Identification information, such as your name, address, and phone numbers.
            <br>(2) Payment information.
            <br>(3) Your location information.
            <br>(4) Name, addresses, and phone numbers listed in your Addresses.
            <br>(5) Comments and emails to us.
            <br>(6) Personal description and photograph in your profile.
            <br>(7) Information and documents regarding identity, including Social Security and driver's license numbers
            <br>(8) Corporate and financial information.
            <br>(9) Device log files and configurations, including Wi-Fi credentials.

            <br>2. Non-essential Information, including the information you give us, information that you choose not to provide, automatic information, and information from other sources. Such information is not mandatory but can help us to improve our services and develop products. You can choose not to provide such information as our service provided to you will not be affected.
            Information You Give Us: we receive and store any information you provide in relation to KakaClo Services, which includes information required during registration (excluding disclosed information of the corporate name and relevant business registration required by applicable law):
            <br>(1) Identification information such as your name, address, and phone numbers.
            <br>(2) Payment information.
            <br>(3) Your location information.
            <br>(4) Name, addresses, and phone numbers listed in your Addresses.
            Information that you can choose not to provide:
            <br>(1) Comments and emails to us.
            <br>(2) Personal description and photograph in your profile.
            <br>(3) Information and documents regarding identity, including Social Security and driver's license numbers.
            <br>(4) Corporate and financial information.
            <br>(5) Device log files and configurations, including Wi-Fi credentials.
            <br>You can choose not to provide such information but then you might not take advantage of KakaClo Services.
            Automatic Information: We automatically collect and store certain types of information about your use of KakaClo Services, including information about your interaction with content and services available through KakaClo Services. We automatically collect information from your browser when you use our service or access our website, including but not limited to: your IP address, browser type, language preference, date and time of visits, device type, operating system, webpage requests, email address and password, location of your device, and content interaction information such as content downloads and network details including information about your internet service provider, device metrics (when a device is in use, application usage, connectivity data, and any errors or event failures), KakaClo Services metrics (e.g., the occurrences of technical errors, your settings preferences, and backup information), location of your device running an application, version and time zone settings, and purchase and content use history.
            Information from Other Sources: Your personal information we legally collect from business associates. We may receive information:
            <br>(1) Updated delivery and address information from our carriers or other third parties, which we use to correct our records and to improve the delivery of your next order.
            <br>(2) Account information, purchase or redemption information, and page-view information from some merchants with which we operate co-branded businesses or for which we provide technical, fulfillment, advertising, or other services.
            <br>(3) Search results and links about your interactions with products and services offered by our subsidiaries, including paid listings.

            <br><span class="weight">IV. For What Purposes Does KakaClo Use Your Personal Information?</span>
            <br> 1. Essential Information provided to us will be used for:
            <br>(1) Products and other services. We use such information to process orders, deliver products, offer services, proceed with payments, and communicate with you about orders, products, services, and promotional offers.
            <br>(2) Voice, image, and camera services. When you use our voice, image, and camera services, we use your voice input, images, videos, and other personal information to respond to your requests, and provide the requested service to you.
            <br> (3) Legal obligations. In certain cases, we collect and use such information to comply with laws. We collect user information regarding the place of business and bank account for identification and other purposes.
            <br>(4) Communication. We use such information to communicate with you about KakaClo Services via different channels (e.g., by phone, email, chat).

            <br>2. Non-essential information will be used for:
            <br>(1) Products and Services. We use such information to develop, improve our services, including troubleshooting, analyzing performance, fixing errors, and improve the usability and effectiveness of KakaClo Services.
            <br>(2) Recommendations and Personalization. We use such information to recommend features, products, and services that might be of interest to you, identifying your preferences and personalizing your experience with KakaClo Services.
            <br>(3) Fraud Prevention and Credit Risks Evaluation. We use personal information to protect users, KakaClo, and others. We may also use such information to evaluate credit risks with scoring.

            <br><span class="weight">V. How Does KakaClo Handle, Share, Transfer, and Disclose Your Personal Information?User information is a significant part of our business, and we will not sell or leak the user information to others, which excludes:</span>
            <br>(1) Information sharing. We will share information with KakaClo's service provider or a specific partner involved in KakaClo's operation that will guarantee the confidentiality of user information. We may also publish user information with the legal requirements to enforce our User Agreement and protect the rights and property of KakaClo, our users, or others. We may provide non-personal user information to other third parties for marketing, advertising, or other purposes.
            <br>(2) User Information transferring.
            <br> a) We will transfer user information to others with your explicit consent. or
            <br> b) We will transfer user information when user information, as a business asset, is involved in case of a merger, acquisition, or bankruptcy, and the new corporate or organization that possess such information will remain subject to this Privacy Policy. Otherwise, your consent will be required.
            <br>(3) Disclosure of User Information.
            <br>We will publish user information with your explicit consent or under applicable laws, legal processes, legal departments, and enforceable government request.
            <br>(4) Protection of KakaClo and Other Parties. We publish user information when appropriate to comply with the law, enforce our User Agreement, or protect the rights, property KakaClo, our users, or others. We may exchange information with other companies and organizations to prevent fraud and to reduce credit risk.
            Other than as set out above, you will receive notice when user information might be shared with third parties, and you can choose not to share the information.

            <br><span class="weight"> VI. Cookies</span>
            <br>We use cookies, pixels, and other technologies (collectively, "cookies") to recognize your browser or device, and learn more about your interests with essential features and services for additional purposes, including:
            <br>1. Recognizing you when you sign-in to use our services. We provide you with product recommendations, personalized content, and other customized features and services.
            <br>2. Keeping track of your specified preferences. We respect your preferences, such as whether or not you would like to see interest-based ads. You may set your preferences through Your Account. Then, KakaClo will keep track of items stored in your cart, conducting research and diagnostics to improve KakaClo's content, products, and services. We will deliver content (for example, ads) relevant to your interests on KakaClo website and third-party sites. This allows us to measure and analyze the performance of our services.
            KakaClo allow user to take advantage of some of our essential features based on Cookies. For instance, if you block or otherwise reject our cookies, you will not be able to add items to your Cart, proceed with payment, or use any KakaClo products and services that require you to sign in. Approved third parties may also set cookies when you interact with KakaClo services, which includes search engines, providers of measurement and analytics services, social media networks, and advertising companies. Third parties use cookies in the process of delivering content, including ads relevant to your interests, to measure the effectiveness of their ads, and to perform services on behalf of KakaClo.
            You can manage browser cookies through your browser settings. The 'Help' feature on most browsers will tell you how to prevent your browser from accepting new cookies, how to have the browser notify you when you receive a new cookie, how to block cookies, and when cookies will expire. If you block all cookies on your browser, neither we nor third parties will transfer cookies to your browser. However, you may have to manually adjust some preferences every time you visit a site and some features and services may not work properly. KakaClo will not use Cookies for any other purposes than stated in this Privacy Policy.

            <br><span class="weight">VII. How Does KakaClo Protect User Information?</span>
            <br>We design our systems with your security and privacy in mind. We work to protect the security of your personal information during transmission by using encryption protocols and software. User information is a significant part of our business, and we will not sell or leak the user information to others.
            We take all possible measures to make sure that irrelevant personal information will not be collected. We will only keep your personal data for as long as required for the purposes set out in this Privacy Policy unless an extended storage period is necessary or as required to comply with any legal obligations to which we are subject.
            We follow the Payment Card Industry Data Security Standard (PCI DSS) when handling credit card data. We maintain physical, electronic, and procedural safeguards in connection with the collection, storage, and disclosure of personal customer information. Our security procedures mean that we may occasionally request proof of identity before we disclose personal information to you. Our devices offer security features to protect them against unauthorized access and loss of data. You can control these features and configure them based on your needs.
            You are responsible to protect against unauthorized access to your password and to your computers, devices, and applications. Be sure to sign off when finished using a shared computer. Please keep your username and password appropriately. KakaClo will encrypt your password and take other security measures to ensure that your information will not be lost, misused, or altered.
            Despite the above security measures, please acknowledge that "perfect and impenetrable security measure" does not exist on the information network. When having online transaction on KakaClo, you will inevitably disclose your personal information, such as your contacts or postal addresses to the counterparty or potential counterparty. Please properly protect your personal information, and do not provide it to others unless you have to. If you find that your personal information has been leaked, especially the username and password, please contact us immediately so that we can take appropriate measures.
            We take all possible measures to make sure that irrelevant personal information will not be collected. However, please be aware that despite our best efforts, no security measures are perfect or impenetrable in information technology. We are accountable for any unauthorized access, disclosure, alteration, or damage of data that harms your legitimate interest due to the inappropriate maintenance of our facility or equipment. KakaClo will take all necessary measures in an endeavor to prevent or reduce the risk if your information is leaked due to force majeure, but will not be liable for your loss in such cases.
            For any breach of security regarding your personal data, we will promptly notify you of the general status and possible impacts, measures taken or will be taken by us, advice on preventing or reducing the risk, remedy measures, etc. We will notify you of the security breach via email, mail. phone, push notification, etc., and will make an announcement in a reasonable and effective way when unable to notify every user.
            We will also report the arrangement of the security breach to the regulatory authority as required at the same time.

            <br><span class="weight">VIII. Are children allowed to use KakaClo Service?</span>
            <br>Our website and service are targeting adults only (referring to citizens aged 18 or over shall be considered adults, or citizens aged 16 or over but not yet 18 whose income from labor constitutes their principal means of support, as different countries or regions have different definitions of adulthood). Children should not create accounts without the consent of their parents or guardians. KakaClo does not sell products for purchase by children. We sell children's products for purchase by adults. We only collect personal information from children with the consent of the child's parent or guardian. Such information will only be used or disclosed under applicable laws, with the explicit consent of the child's parent or guardian, or for protection purposes of the children.
            Relevant data will be deleted once we discover that we have collected personal information of children without the consent of the child's parents, or guardian.
            <br><span class="weight">IX. Modification</span>
            <br>If you choose to use KakaClo Service, any dispute regarding your use or privacy will be subject to this Privacy Policy. If you have any questions regarding KakaClo's Privacy Policy, please contact us with a specific description. We will endeavor to respond. Our business changes constantly, and our Privacy Policy will also change. You are responsible to check our websites frequently to see recent changes. Unless otherwise stated, our current Privacy Policy applies to all information that we have about you and your account. We stand behind the promises we make and will never materially change our policies and practices to make them less protective of user information collected in the past without the consent of you.

            <br><span class="weight">IX. How to Get in Touch with Us?</span>
            <br> If you have any question, opinion, or suggestion, please contact us via support@kakaclo.com.
            <br>We will respond within a reasonable period.
            <br>If you are not satisfied with our response, especially when your legitimate interest is harmed due to our handling of your personal information, you can contact our customer support for a settlement through amicable negotiation, and you reserve the right to file a lawsuit in a court that has jurisdiction over KakaClo's place of business in China.
          </p>
        </div>
      </div>
    </template>
  </WebsiteNavigation>
</template>

<script>
import WebsiteNavigation from '@/components/layout/WebsiteNavigation.vue'
// import API from '@/api/index'
export default {
  components: { WebsiteNavigation },
  data() {
    return {
    }
  },
  created() { },
  activated() { },
  methods: {}
}
</script>

<style lang="scss" scoped>
.main {
  width: 1400px;
  margin: 0 auto;
  h1 {
    font-size: 40px;
    font-weight: 500;
    color: #121e26;
    text-align: center;
    // width: 1150px;
  }
  p {
    color: #606060;
    line-height: 30px;
    margin-bottom: 100px;
  }
  .weight{
    font-size: 18px;
    font-weight: bold;
    display: inline-block;
    margin-top: 20px;
  }
}
</style>
